<template>
  <div>
    <section class="footer-betslip-section row  px-2" @click="showBetslip" style="max-height:47px">


      <div class="col-3 slip-slip-bets px-0 text-center text-light" @click="goTo('bets')">
        Betslip <span class="slip-counter" v-text="betslip_count"></span>
      </div>


      <div class="col-4 px-0">
        <div class="slip-odds ">
          Total Odds: <strong id="total_odd">{{ odds | formatOdds }}</strong> <br>
          Win:<strong id="possible_win">{{ payout | currency }}</strong> <br>
        </div>


      </div>
      <div class="col-3 slip-slip px-0 d-none">

      </div>

      <div class="col px-0 line-height-1">
        <div class="slip-btn-wrapper">
          <a class="slip-btn btn shadow">Place Bet</a>
          <!--<a @click="goTo('betslip')" class="slip-btn btn shadow">Place Bet</a>-->
        </div>

      </div>


    </section>

    <!-- share bet modal -->
    <span id="open-sharebet" class="hidden"></span>

    <span id="betslip-init" class="hidden"></span>

    <!-- Betslipmodal -->
    <div id="betslip-modal" v-bind:class="modalClass" style=" z-index: 99999">

      <div id="status"></div>

      <!-- Modal content -->
      <div v-bind:class="modalContentClass" id="betmobile">

        <section class="betslip-header">
          <div class="p-2 hover-pointer" data-toggle="collapse" href="#collapseBetmenu" role="button"
            aria-expanded="false" aria-controls="collapseBetmenu">
            <i class="bi bi-three-dots-vertical text-light"></i>
          </div>
          <div class="row m-0 p-0">
            <div class="col-4 p-0 m-0">
              <div class="d-flex p-2">
                <span class="slip-counter-header">
                  {{ betslip_count }}
                </span>
                <span class="slip-text-header">
                  Betslip
                </span>
              </div>
            </div>
            <div class="col-4 p-0 m-0">
              <div class="p-2 text-center remove-txt" @click="clearOutrightBetSlip">
                Remove All
              </div>
            </div>
            <div class="col-4 p-0 m-0">
              <div class="text-light p-2 text-center close-text" id="faso-close">
                Close [x]
              </div>
            </div>
          </div>
        </section>
        <div class="collapse details-collapse py-2 px-4" id="collapseBetmenu" v-show="betslip_count > 0">
          <div class="text-yellow">
            <strong>Settings</strong>
          </div>
          <div class="text-yellow">
            Enable odd change automatically
          </div>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="checkbox" id="customRadioInline1" name="customRadioInline1" class="custom-control-input"
                v-model="accept_odds_changes">
              <label class="custom-control-label text-light" for="customRadioInline1">Accept Odds Changes</label>
            </div>
            <!--<div class="custom-control custom-radio custom-control-inline">
            <input checked type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
            <label class="custom-control-label text-light" for="customRadioInline2">Disable</label>
          </div>-->
          </div>

        </div>
        <hr class="mt-0 mb-1">

        <div class="faso-modal-body" style="height: 30em;overflow-y: auto;">

          <Pick v-show="betslip_count > 0" v-for="(bet, index) in betslip.picks" v-bind:key="index" v-bind:bet="bet">
          </Pick>


          <!--<section v-show="betslip_count === 0">

          <div class="form-group">
            <label style="color: black">Do you have a booking code? Enter your booking code below</label>
            <input type="text" class="form-control input-login" placeholder="e.g FBXXX" v-model="booking_code">
          </div>

        </section>-->
          <div class="spacer" style="width: 100%; height:30px;"></div>

        </div>

        <div class="" style="position: fixed;bottom: 0;width: 100%;/*height: 12em*/;max-width: 768px;">

          <div v-if="betslip_count !== 0" class="faso-modal-footer d-flex flex-column pb-0" style="padding: 2px 16px;">

            <section class="dets px-0 py-2 " style="background-color: var(--darkBackground)">
              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="dets-title text-yellow">
                    Total Odds
                  </div>
                  <div class="text-blue small-text">
                    Min. 1.075
                  </div>
                </div>
                <div class="col-6 m-0 p-0">
                  <div class="dets-value text-yellow text-right">
                    {{ betslip.odds | formatOdds }}
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-0">

              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="dets-title text-yellow">
                    Stake amount
                  </div>
                  <div class="text-blue small-text line-height-1">
                    Min. Ksh 20
                  </div>
                  <div class="text-blue small-text line-height-1">
                    Max. Ksh 100,000
                  </div>

                </div>
                <div class="col-6 m-0 p-0 slip-amount-bg">
                  <div class="input-group mb-1">
                    <div class="input-group-prepend" @click="decrementStake">
                      <span class="input-group-text"><i class="bi bi-minus"></i>-</span>
                    </div>
                    <input id="stake" type="number" @keyup="checkLetters" class="form-control slip-amount"
                      placeholder="100" aria-label="Amount" v-model="stake">
                    <div class="input-group-append" @click="incrementStake">
                      <span class="input-group-text"><i class="bi bi-minus"></i>+</span>
                    </div>
                  </div>

                </div>
              </div>
              <hr class="mt-1 mb-1">
              <div class="row m-0 p-0">
                <div class="col-6 m-0 p-0">
                  <div class="dets-title text-yellow">
                    Final Payout
                  </div>
                  <div class="text-blue small-text hover-pointer" data-toggle="collapse" href="#collapseDetails"
                    role="button" aria-expanded="false" aria-controls="collapseDetails">
                    <i class="bi bi-info-circle"></i> Details
                  </div>
                </div>
                <div class="col-6 m-0 p-0">
                  <div class="dets-value text-yellow text-right">
                    {{ betslip.payout | currency }}
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-1">
              <!--  Add accordion for details  -->
              <div class="collapse details-collapse px-2 " id="collapseDetails">
                <div class="d-none justify-content-between">
                  <div class="dets-sub small-text text-blue">
                    Excise tax
                  </div>
                  <div class="dets-val small-text text-blue">
                    -Ksh 8.00
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="dets-sub small-text text-blue">
                    Stake tax
                  </div>
                  <div class="dets-val small-text text-blue">
                    {{ stake - ((stake * 100) / (100 + 7.5)) | currency }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="dets-sub small-text text-blue">
                    Stake After tax
                  </div>
                  <div class="dets-val small-text text-blue">
                    {{ ((stake * 100) / (100 + 7.5)) | currency }}
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="dets-sub small-text text-blue">
                    WH tax
                  </div>
                  <div class="dets-val small-text text-blue">
                    {{ betslip.tax | currency }}
                  </div>
                </div>

              </div>

              <div class="py-2 text-danger text-center text-underline d-none">
                <u v-if="has_suspended_picks" @click="removeSuspendedPicks">(REMOVE EXPIRED GAMES)</u>
              </div>
              <section class="shareSlipSection collapse " id="collapseShareSlip">

                <div class="text-yellow text-center matches-title mb-2">
                  Share Betslip
                </div>

                <div class="social-icons d-flex justify-content-around px-2 text-center mb-2">
                  <a href="https://www.facebook.com/tuchezeKE/" target="_blank" title="Tweet"
                    onclick="window.open('https://www.facebook.com/tuchezeKE/' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                    <img src="/img/facebook.png"><br>
                    <span class="text-blue small-text"> Facebook</span>
                  </a>

                  <a href="https://instagram.com/tucheze?utm_medium=copy_link&t=" title="Share on Facebook"
                    onclick="window.open('https://instagram.com/tucheze?utm_medium=copy_link' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;"
                    target="_blank">
                    <img src="/img/instagram.png"> <br>
                    <span class="text-blue small-text"> Instagram</span>
                  </a>

                  <a data-action="share/whatsapp/share" target="_blank"
                    onclick="window.open('whatsapp://send?text=' + document.getElementById('share-bet-msg').textContent); return false;">
                    <img src="/img/whatsapp.png"><br>
                    <span class="text-blue small-text"> Whatsapp</span>
                  </a>

                  <a href="https://twitter.com/tucheze?s=11" target="_blank" title="Tweet"
                    onclick="window.open('https://twitter.com/tucheze?s=11text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                    <img src="/img/twitter.png"><br>
                    <span class="text-blue small-text"> Twitter</span>
                  </a>

                  <a data-action="share/telegram/share" target="_blank"
                    onclick="window.open('https://telegram.me/share/url?url=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&text=' + encodeURIComponent(document.getElementById('share-bet-msg').textContent)); return false;">
                    <img src="/img/telegram.png"><br>
                    <span class="text-blue small-text"> Telegram</span>
                  </a>

                </div>

                <div class="row p-0 m-0 px-2 mb-2">
                  <div class="col-7 px-1">
                    <div id="share-bet-link" class="share-betslip-btn" style="color: #FFFFFF">https://tucheze.com/share
                    </div>
                    <div class="d-none" id="share-bet-code">{{ code }}</div>
                    <div class="d-none" id="share-bet-odds">{{ odds }}</div>
                    <div class="d-none" id="share-bet-msg">{{ msg }}</div>
                  </div>
                  <div class="col-5 px-1">
                    <button class="share-btn" @click="copyShareBetLink">{{ copyText }}</button>
                  </div>
                </div>

                <div class="text-center slip-ttxt py-3 d-none">
                  <div class="text-blue mb-3">You can also download a copy of your betslip in pdf</div>
                  <div>
                    <a href="" class="login-button py-2 px-4 ">Download betslip</a>
                  </div>
                </div>

                <hr class="m-1">

                <div class="text-center slip-ttxt py-3 d-none">
                  <div class="text-blue mb-3">Want to print out the betslip?</div>
                  <div>
                    <a href="" class="login-button py-2 px-4 ">Print betslip</a>
                  </div>
                </div>

              </section>
              <div class="text-center">
                <a @click="handlePlaceBetClick" class="join-button py-2 form-control">Place Bet</a> <br>
                <a id="open-sharebet" href="#collapseShareSlip" class="login-button py-2 px-4 " data-toggle="collapse"
                  data-target="#collapseShareSlip" aria-expanded="false" aria-controls="collapseShareSlip"
                  @click="shareBet">Share Bet</a>
              </div>
            </section>
          </div>
        </div>

      </div>

    </div>
    <!-- Betslipmodal -->
  </div>

</template>

<script>

import axios from "@/services/api";
import Pick from './Pick'
import { pushToDataLayer } from '@/utils/gtm';

export default {
  name: "bottom-navigation",
  props: {
    // page: {
    //   required: true
    // },
  },
  data: function () {
    return {
      stake: 50,
      loading: '',
      bestlip_visible: false,
      code: '',
      msg: '',
      share_odds: '',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: ''
    }
  },
  methods: {
    checkLetters: function () {
      var testString = this.stake;

      if (parseInt(testString)) {
        /* do something if letters are found in your string */

      } else {
        // this.stake = 1;

      }
    },
    incrementStake: function () {
      console.log(this.stake)
      if (!this.can_get_bonus) {
        this.stake = this.stake + 50;
      }

    },
    decrementStake: function () {
      if ((this.stake - 50) > 49 && !this.can_get_bonus) {
        this.stake = this.stake - 50;
      }
    },
    loadBookingCode: function () {

      if (this.booking_code.trim().length > 4) {

        if (this.currentRouteName !== "share") {

          this.$router.push({ name: 'share', params: { code: this.booking_code.trim() } });

        } else {

          this.EventBus.$emit('share:code', this.booking_code.trim());
        }

      } else {

        this.$toast.open({
          message: 'Please enter a valid booking code to proceed',
          type: 'error',
          position: 'top'
        });

      }

    },
    shareBetMessage: function () {

      //var code = this.code;
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * 200);
      this.msg = this.msg.replace('{possible_win}', toWin);

    },

    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {

      switch (parseInt(sport_id)) {

        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }

      return ""
    },
    show: function () {

      this.jQuery('#betslip-modal').modal('show');
      //var modal = document.getElementById("betslip-modal");
      //modal.style.display = "block";
      this.bestlip_visible = true;
    },
    tax: function () {
      const wage_tax = 7.5;
      const winning_tax = 20 / 100;
      let stake = this.stake;
      let odd = this.betslip.odds;

      let stake_after_tax = stake - (stake * wage_tax) / 100;

      let winning = odd * stake_after_tax;
      let w_tax = (winning - stake_after_tax) * winning_tax;

      this.withholding_tax = w_tax;

    },
    handlePlaceBetClick(event) {
      this.placeBet(event);
    },
    trackPlaceBetSuccessfulButtonClick(event) {
      pushToDataLayer('gtm.betPlacedSuccessful', {
        category: 'Button',
        action: 'Click',
        label: 'betPlacedSuccessful',
        element: event.target
      });
    },
    trackPlaceBetUnsuccessfulButtonClick(event) {
      pushToDataLayer('gtm.BetPlaceUnsuccessful', {
        category: 'Button',
        action: 'Click',
        label: 'BetPlaceUnsuccessful',
        element: event.target
      });
    },
    placeBet: function (event) {
      this.reset();
      var p = this.getProfile();

      if (!p) {

        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.setValue("placeBet", 2);
        this.$router.push({ name: 'login', params: {} });
        return;
      }

      var bet_amount = this.getValue("stake");
      var booking_code = this.getValue("booking_code");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");


      if (bet_amount < 1) {

        this.$toast.open({
          message: 'Please enter bet amount greater of Ksh. 10 or more',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.$toast.open({
          message: 'Please Select atleast one outcome to continue',
          type: 'error',
          position: 'top'
        });
        return;
      }

      if (!this.accept_odds_changes) {

        this.$toast.open({
          message: 'Accept Odds Changes to Proceed',
          type: 'error',
          position: 'top'
        });
        return;

      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          odd_id: v.odd_id,
          match_id: v.match_id,
        });

      });

      var data = {
        stake: bet_amount,
        bets: bets,
        code: booking_code,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: document.referrer,
        source: this.isMobile() ? 2 : 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_OUTRIGHT_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var profile = res.data.message.profile;
          var message = res.data.message.message;
          vm.setProfile(profile);
          vm.hideBetslip();
          vm.clearOutrightBetSlip();
          vm.trackPlaceBetSuccessfulButtonClick(event);
          // vm.setSuccess("Success", );
          this.$toast.open({
            message: message,
            type: 'success',
            position: 'top'
          });
          vm.removeObject('booking_code');
          //vm.goHome();

        })
        .catch(err => {

          vm.loading = '';
          vm.trackPlaceBetUnsuccessfulButtonClick(event);

          if (err.response) {

            console.log(JSON.stringify(err.response, undefined, 4));

            var message = "";

            if (parseInt(err.response.status) === 428) {

              message = err.response.data.message.message;
              var amount = err.response.data.message.amount;

              vm.EventBus.$emit('deposit:popup', {
                amount: amount,
                message: message,
              });

            }

            if (parseInt(err.response.status) === 422) {

              message = err.response.data.message;
              this.$toast.open({
                message: message,
                type: 'error',
                position: 'top'
              });
              this.$toast.open({
                message: message,
                type: 'error',
                position: 'top'
              });

              return
            }

            if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400) {

              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              vm.logout();
              return;

            } else {
              this.$toast.open({
                message: message,
                type: 'error',
                position: 'top'
              });
              this.$toast.open({
                message: message,
                type: 'error',
                position: 'top'
              });
            }

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }

        })
    },
    oddStatus: function () {

      var picks = this.betslip.picks;

      if (picks === undefined || picks.length === 0) {

        return;

      }

      var bets = [];

      this.jQuery.each(picks, function (k, v) {

        bets.push(v.odd_id);

      });

      var data = {

        odd_id: bets.join(',')
      };

      if (bets.length === 0) {

        return;

      }

      var vm = this;

      var path = process.env.VUE_APP_URL_OUTRIGHT_ODD_STATUS;

      axios.post(path, JSON.stringify(data))
        .then(res => {

          var bets = res.data.message;

          vm.jQuery.each(bets, function (k, v) {

            var oddID = v.id;
            var odds = v.odds;
            var previous_odds = v.previous_odds;
            var status = v.status;
            var active = v.active;
            var producer_status = v.producer_status;

            // get original odds
            // check odds changes
            vm.jQuery.each(picks, function (k1, v1) {

              if (parseInt(v1.odd_id) === parseInt(oddID)) {

                var oddChangeText = "";
                odds = vm.formatOdds(odds);

                if (odds !== vm.formatOdds(v1.odd)) {

                  // odds changed
                  if (odds > v1.odd) {

                    var direction = 1
                  } else {
                    direction = 0
                  }

                  var directionTxt = parseInt(direction) === 1 ? 'odds has gone up ' : 'odds has gone down ';
                  oddChangeText = directionTxt + ' from ' + v1.odd + ' to ' + odds;

                }

                v1.status = status;
                v1.active = active;
                v1.odds = odds;
                v1.odd = odds;
                v1.previous_odds = previous_odds;
                v1.producer_status = producer_status;
                v1.odds_change_text = oddChangeText;
                picks[k1] = v1;
              }

            })

          });

          vm.saveObject("oslip", picks);
          vm.autoRefreshOutrightUI(vm.$vnode.tag);

        })
        .catch(err => {

          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          } else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          } else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }
        })
    },
    getOddStatus: function (odd) {

      if (odd.active === 0 || odd.producer_status === 0) {

        return '<div class="deactivated-odds">Outcome suspended</div>';

      }

      if (parseInt(odd.status) === 0 || parseInt(odd.status) === -1) {

        return '<div class="deactivated-odds">Outcome Deactivated</div>';

      }

      if (odd.odds_change_text && odd.odds_change_text.length > 0) {

        return '<div class="odds-change" style="">' + odd.odds_change_text + '</div>';

      }

    },
    initSharebetModal: function () {

      var modal = document.getElementById("sharebet-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-sharebet");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#sharebet-modal")) {

          modal.style.display = "none";
        }

      });

    },
    initBetslipModal: function () {

      var vm = this;

      // Get the modal
      var modal = document.getElementById("betslip-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("betslip-init");

      // Get the <span> element that closes the modal
      var span = document.getElementById("faso-close");

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        vm.previous_odds = vm.betslip.odds;
        vm.oddStatus();
      }

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      }

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {

          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });

      document.addEventListener("click", e => {

        if (e.target === document.querySelector("#betslip-modal")) {

          modal.style.display = "none";
        }

      });


    },
    showBetslip: function () {

      document.getElementById("betslip-init").click();

    },
    hideBetslip: function () {

      document.getElementById("faso-close").click();

    },
    shareBet: function () {

      var p = this.getProfile();

      var betslipData = this.betslip;

      if (betslipData.total === 0) {

        this.$toast.open({
          message: 'Please Select atleast one outcome to continue',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var bets = [];

      this.jQuery.each(betslipData.picks, function (k, v) {

        bets.push({
          market_id: v.market_id,
          match_id: v.match_id,
          outcome_id: v.outcome_id,
          specifier: v.specifier,
        });

      });

      var data = {
        profile_id: p.id,
        bets: bets,
        bet_type: 1
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_SHARE;

      axios.post(path, JSON.stringify(data))
        .then(res => {

          vm.loading = '';
          vm.code = res.data.message.code;
          vm.msg = res.data.message.message;
          vm.share_odds = betslipData.odds;
          vm.shareBetMessage();
          console.log(vm.msg);
          vm.copyText = 'Copy';
          vm.copyBookingCode = 'Click to Copy'
          document.getElementById("open-sharebet").click();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.response.data.message));

          } else if (err.request) {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));

          } else {

            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));

          }
        })
    },
    copyShareBetLink: function () {

      var link = "https://tucheze.com/share/";
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "book#" + this.code + "#stakeAmount";
      this.copyToClipboard(link);
      this.copyBookingCode = 'Booking Code Copied';

    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    bal: function () {

      return this.formatCurrency(this.profile.b1);

    },

    iconSize: function () {

      return 28;
    },
    profile: function () {

      return this.myProfile;

    },
    liveGames: function () {

      return this.$store.state.live_match_counter

    },
    homePageIcon: function () {

      if (this.page === 'home') {

        return this.getURL("/assets/images/home_icon_selected.svg");

      }

      return this.getURL("/assets/images/home_icon.svg");

    },
    homePageFontColor: function () {

      if (this.page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },
    activeBets: function () {

      var p = this.getProfile();
      if (!p) {

        return 0;

      }

      return p.b

    },
    odds: function () {

      return this.betslip.odds
      //return this.$store.state.betslip.odds

    },
    payout: function () {

      return this.formatCurrency(this.betslip.payout);
    },
    betslip: function () {

      return this.$store.state.outrightbetslip
    },
    has_suspended_picks: function () {

      return this.$store.state.has_suspended_outright_picks

    },
    isLoggedIn: function () {

      var p = this.getProfile();
      if (!p) {
        return false;
      }

      return true;
    },
    modalClass: function () {

      if (parseInt(this.betslip_count) === 0) {

        return "faso-modal-booking-code"

      } else {

        return "faso-modal"

      }
    },
    modalContentClass: function () {

      if (parseInt(this.betslip_count) === 0) {

        return "faso-modal-content-booking-code"

      } else {

        return "faso-modal-content"
      }

    }

  },
  filters: {

    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
  watch: {

    stake: function (newValue) {

      // update stake
      this.setValue("stake", newValue);

      // recalculate winnings
      this.autoRefreshOutrightUI(this.$vnode.tag);

    }
  },
  components: {
    Pick,
  },
  mounted: function () {

    // reset stake
    this.setValue("stake", 50);
    this.initBetslipModal();
    this.previous_odds = this.betslip.odds;

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;

    this.EventBus.$on('event:outrightbetslip:show', function () {

      vm.showBetslip();

    });

    this.EventBus.$on('event:outrightbetslip:hide', function () {

      vm.hideBetslip();

    });

    this.EventBus.$on('event:outrightbetslip:placeBet', function () {

      vm.placeBet(true);

    });

    this.EventBus.$on('profile:balance', function (payload) {

      vm.myProfile = payload;

    });

    this.EventBus.$on('outrightodds:changed', function (payload) {

      vm.odds_changed = true;
      vm.updateOutrightOdd(payload.id, payload.odds, payload.previous_odds, payload.active, payload.status);

    });

  }
}
</script>
